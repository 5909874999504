import './Header.css'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Header = ({ active, toggleHeader }) => {
    const[selectedHead, setSelectedHead] = useState(0)

    useEffect(() => {
        if (!active) setSelectedHead(-1) 
    }, [active])

    const setActive = (idx) => {
        return selectedHead === idx ? 'active' : ''
    }

    const toggleAndSelect = (idx) => {
        setSelectedHead(idx)
        toggleHeader(true)
    }

    return (
        <div className = "ui secondary pointing menu">
            <Link 
                onClick={() => toggleAndSelect(0)} 
                to="/" 
                className={`item heading ${setActive(0)}`}>
                    Voronoi Voyager
            </Link>
            <Link 
                onClick={() => toggleAndSelect(1)} 
                to="/contact" 
                className={`item heading ${setActive(1)}`}>
                    Contact
            </Link>
        </div>
    );
};

export default Header;