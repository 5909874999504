import React from 'react';
import './Contact.css';
import reesePhoto from '../assets/WebsitePhoto.png';
const Contact = () => {
  return (
    <div>
      <p className='center'>
        If you have any questions, comments, requests or inquiries of any kind,
        feel free to contact me at <strong>reeseeifler@gmail.com</strong>
      </p>
      <img className='ui centered large image' src={reesePhoto} alt='Reese' />
      <p className='center'>Reese Eifler - MAN OF EERIE LETTERS developer</p>
    </div>
  );
};

export default Contact;
