import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Contact from './components/Contact';
import Privacy from './components/Privacy-2';
import Terms from './components/Terms';

class App extends Component {
  state = {
    headerIsOn: true,
  };

  toggleHeader = (on) => {
    this.setState(() => ({
      headerIsOn: on,
    }));
  };

  render() {
    return (
      <div id='scoped-content' className='home-background'>
        <div className='ui container'>
          <Header
            active={this.state.headerIsOn}
            toggleHeader={this.toggleHeader}
          />
          <Route
            exact
            path='/'
            render={() => <Home toggleHeader={this.toggleHeader} />}
          />
          <Route path='/contact' component={Contact} />
          <Route
            path='/privacy-policy'
            render={() => <Privacy toggleHeader={this.toggleHeader} />}
          />
          <Route
            path='/terms-of-service'
            render={() => <Terms toggleHeader={this.toggleHeader} />}
          />
        </div>
      </div>
    );
  }
}

export default App;
