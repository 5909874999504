import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Terms = ({ toggleHeader }) => {
    useEffect(() => {
        toggleHeader(false)
    }, [toggleHeader])
    return (
        <div>
            <Link 
                to='./privacy-policy' 
                className='other-legal-link'>
                    Privacy Policy
            </Link>
            <h1>Terms of Service</h1>
            <p>
                <strong>Please read these Terms of Service and our Privacy Policy (<Link to='/privacy-policy' className='black-link'>https://voronoivoyager.com/privacy-policy</Link>) carefully before using MAN OF EERIE LETTERS LLC’s (“MAN OF EERIE LETTERS”) Services.</strong>
            </p>
            <p>
            Whenever you use the Services, you agree that these Terms of Service will control the relationship between you and MAN OF EERIE LETTERS. If you do not agree to all the terms and conditions, you must not use our Services.
            </p>
            <h2>1. Definitions</h2>
            <ol>
                <li>
                Account — Any account provided by MAN OF EERIE LETTERS that you create to access certain Services.
                </li>
                <li>
                IP right — Any intellectual property right, including but not limited to any copyright, trademark, patent, trade secret, industrial design right, database right, software, idea, concept, method, invention, content, item, or combinations thereof.
                </li>
                <li>
                Privacy Policy — MAN OF EERIE LETTERS policy regarding privacy. The current version is found at <Link to='/privacy-policy' className='black-link'>https://voronoivoyager.com/privacy-policy</Link>.
                </li>
                <li>
                Service or Services — Any game, website, application, and other related services by MAN OF EERIE LETTERS and its affiliates.
                </li>
                <li>
                Terms of Service or Terms — These terms of service.
                </li>
            </ol>
            <h2>2. Ownership and Limited License - Services Licensed Not Sold</h2>
            <ol>
                <li>
                    <strong>Ownership. </strong>All rights, title and interest in and to the Services (including without limitation any games, titles, computer code, themes, objects, characters, character names, stories, dialogue, catch phrases, concepts, artwork, animations, sounds, musical compositions, audio-visual effects, methods of operation, moral rights, documentation, in-game chat transcripts, character profile information, recordings of games played using a MAN OF EERIE LETTERS game client, and MAN OF EERIE LETTERS game clients and server software) are owned or licensed by MAN OF EERIE LETTERS. MAN OF EERIE LETTERS reserves all rights, including without limitation, all IP Rights or other proprietary rights, in connection with its games and the Service.
                </li>
                <li>
                    <strong>License. </strong>Subject to your agreement and continuing compliance with these Terms of Service and any other relevant MAN OF EERIE LETTERS policies, MAN OF EERIE LETTERS grants you a non-exclusive, non-transferable, revocable limited license, subject to the limitations in these Terms, to access and use the Services for your own non-commercial entertainment purposes. You agree to not use the Services for any other purpose.
                </li>
                <li>
                    <strong>License Limitations. </strong> Any use of the Services in violation of these License Limitations is strictly prohibited, and may result in the immediate revocation of your limited license and may subject you to liability for violations of law.
                </li>
            </ol>
            <h3>You acknowledge you will not directly or indirectly:</h3>
            <ol>
                <li>
                Partake in any activity or action that MAN OF EERIE LETTERS deems to be against the spirit or intent of the Services.
                </li>
                <li>
                Copy, distribute or reproduce any Service or related content.
                </li>
                <li>
                Attempt to harass, abuse, or harm, or advocate or incite harassment, abuse, or harm of another person, group, or MAN OF EERIE LETTERS itself.
                </li>
                <li>
                Initiate, assist, or become involved in any form of attack, including without limitation distribution of a virus, denial of service attacks upon the Service, or other attempts to disrupt the Service or other person's use or enjoyment of the Service.
                </li>
                <li>
                Make available through the Service any material or information that infringes any copyright, trademark, patent, trade secret, right of privacy, right of publicity, or other right of any person or entity or impersonates any other person, including without limitation a MAN OF EERIE LETTERS employee.
                </li>
                <li>
                Attempt to gain unauthorized access to the Service or Accounts not belonging to you.
                </li>
            </ol>
            <h3>Your Account</h3>
            <ol>
                <li>
                No matter what else is said in these Terms or anywhere else within the Services, you have no right or title to the Account you create on our Service and your Account is not your property. Your Account is owned by MAN OF EERIE LETTERS and is licensed to you under the same rules as 2(a).
                </li>
                <li>
                Further, we may delete Accounts that are deemed inactive. “Inactive” will be defined by MAN OF EERIE LETTERS based on average usage of the Services from other users. If you would like us to delete your Account, please alert us at <strong>reese@manofeerieletters.com</strong>.
                </li>
            </ol>
            <h2>3. User Content</h2>
            <p>
            MAN OF EERIE LETTERS assumes no responsibility for the conduct of any user submitting any content created by users (“User Content”), and assumes no responsibility for monitoring the Service for inappropriate content or conduct. We are unable to pre-screen or monitor all User Content and we will not do so. Your use of the Service is at your own risk.
            </p>
            <p>
            When you transmit or upload User Content, you agree to abide by the following rules:
            </p>
            <ol>
                <li>
                    All content will be accurate.
                </li>
                <li>
                    All content will be free of any infringing material.
                </li>
                <li>
                All content will not be in violation of any law, contractual restrictions, or other parties’ rights.
                </li>
                <li>
                All content will be free of viruses, adware, spyware, worms, or other malicious code.
                </li>
            </ol>
            <p>
            You hereby grant MAN OF EERIE LETTERS a revocable, perpetual, transferable, fully paid-up, royalty-free, worldwide license (including the right to sublicense and assign to third party) and right to copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize, publish, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, communicate to the public by telecommunication, display, perform, enter into computer memory, and use and practice, in any way, your User Content as well as all modified and derivative works thereof in connection with our provision of the Service, including marketing and promotions of the Service. You also hereby grant to MAN OF EERIE LETTERS the right to authorize others to exercise any of the rights granted to MAN OF EERIE LETTERS under these Terms of Service. You further hereby grant to MAN OF EERIE LETTERS the unconditional, irrevocable right to use and exploit your name, likeness and any other information or material included in any User Content and in connection with any User Content, without any obligation to you. Except as prohibited by law, you waive any rights of attribution and/or any moral rights you may have in your User Content, regardless of whether your User Content is altered or changed in any manner. MAN OF EERIE LETTERS does not claim any ownership rights in your User Content and nothing in these Terms of Service is intended to restrict any rights that you may have to use and exploit your User Content. You agree that you can revoke this license only by sending notification to: <strong>reese@manofeerieletters.com</strong>.
            </p>
            <h2>4. Fees and Payment Terms</h2>
            <p>
            Purchases purchased within the Services on other platforms such as Valve Steam, Facebook, Apple iOS, or Android will be subject to those platforms' payment terms and conditions. MAN OF EERIE LETTERS does not control how you can pay on those platforms. Please review those platforms' terms of Service for additional information. 
            </p>
            <h2>5. Duration</h2>
            <p>
            Unless modified or amended by MAN OF EERIE LETTERS, this agreement and its provisions shall remain in effect. Termination of any license granted by MAN OF EERIE LETTERS under this agreement does not affect any other provisions of this agreement.
            </p>
            <h2>6. Account Access and Permissible Assignment</h2>
            <ol>
                <li>
                You warrant and represent that you: 1) are at least 18 years of age and otherwise legally competent to read, understand and accept the provisions of this agreement on behalf of yourself; 2) are at least 18 years of age and otherwise legally competent to read, understand and accept the provisions of this agreement on behalf of yourself and a minor age 13-17 for whom you are legally permitted to allow access to the Services; or 3) are a minor age 13-17 who has been authorized under the provisions of Section 6(c) below. <strong>If you are under 13 years of age, you must not create an Account, use any part of the Services, or submit personal information through the Services.</strong>
                </li>
                <li>
                Each Account may only be used by one person. If a minor has been allowed access to an Account under Section 6(c) below, only that minor may use the Account thereafter. You may not make any assignment or transfer of rights, obligations or liabilities related to this agreement and any attempt by you to do so is null and void.
                </li>
                <li>
                If you are at least 18 years of age and otherwise legally competent to read, understand and accept the provisions of this agreement on behalf of yourself and a minor age 13-17 for whom you are legally permitted to allow access to the Services, you can choose to allow access to the Services by that minor instead of yourself subject to the following provisions:
                </li>
            </ol>
            <p>
            A. You acknowledge, and further agree they are entering into an agreement with your consent;
            </p>
            <p>
            B. You acknowledge, and further agree you are responsible for all the provisions in these Terms;
            </p>
            <p>
            C. You acknowledge, and further agree, you are legally responsible for all actions of that minor, including but not limited to any payments, damages and/or liabilities related to the actions of that minor;
            </p>
            <p>
            D. In consideration for MAN OF EERIE LETTERS allowing access to the Services by a minor, and in addition to the provisions of Section 9 below, the foregoing adult hereby guarantees and agrees to pay for any and all liabilities of any nature whatsoever incurred under this agreement and to defend, indemnify and hold harmless MAN OF EERIE LETTERS with respect thereto.
            </p>
            <h2>Service and Availability</h2>
            <ol>
                <li>
                You acknowledge that MAN OF EERIE LETTERS may in its sole and absolute discretion provide subsequent versions, enhancements, modifications, upgrades or patches related to any part of the Service.
                </li>
                <li>
                You acknowledge that admission to the Service may be interrupted for reasons within or beyond the control of MAN OF EERIE LETTERS, and that MAN OF EERIE LETTERS cannot and does not guarantee you will be able to use the Services whenever you wish to do so.
                </li>
            </ol>
            <h2>8. Additional Warranties and Representations by You</h2>
            <p>
            YOU ACKNOWLEDGE THAT MAN OF EERIE LETTERS AND MAN OF EERIE LETTERS AFFILIATES ARE NOT LIABLE.
            </p>
            <p>
            (1) FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING FOR LOSS OF PROFITS, GOODWILL OR DATA, IN ANY WAY WHATSOEVER ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE SERVICE; OR
            </p>
            <p>
            (2) FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OTHER USERS OF THE SERVICE AND OPERATORS OF EXTERNAL SITES.
            </p>
            <p>
            THE RISK OF USING THE SERVICE RESTS ENTIRELY WITH YOU AS DOES THE RISK OF INJURY FROM THE SERVICE.
            </p>
            <p>
            TO THE FULLEST EXTENT ALLOWED BY ANY LAW THAT APPLIES, THE DISCLAIMERS OF LIABILITY IN THESE TERMS APPLY TO ALL DAMAGES OR INJURY CAUSED BY THE SERVICE, OR RELATED TO USE OF, OR INABILITY TO USE, THE SERVICE, UNDER ANY CAUSE OF ACTION IN ANY JURISDICTION, INCLUDING, WITHOUT LIMITATION, ACTIONS FOR BREACH OF WARRANTY, BREACH OF CONTRACT OR TORT (INCLUDING NEGLIGENCE).
            </p>
            <p>
            TO THE MAXIMUM EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, THE TOTAL LIABILITY OF MAN OF EERIE LETTERS AND/OR MAN OF EERIE LETTERS AFFILIATES IS LIMITED TO THE TOTAL AMOUNT YOU HAVE PAID MAN OF EERIE LETTERS AND/OR THE MAN OF EERIE LETTERS AFFILIATE IN THE ONE HUNDRED AND EIGHTY DAYS (180) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM.
            </p>
            <p>
            IF YOU HAVE NOT PAID MAN OF EERIE LETTERS OR ANY MAN OF EERIE LETTERS AFFILIATE ANY AMOUNT IN THE ONE HUNDRED AND EIGHTY DAYS (180) DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH CLAIM, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH MAN OF EERIE LETTERS AND/OR ANY MAN OF EERIE LETTERS AFFILIATE IS TO STOP USING THE SERVICE AND TO CANCEL YOUR ACCOUNT.
            </p>
            <p>
            YOU REPRESENT AND WARRANT THAT YOU ARE NOT LOCATED IN A COUNTRY THAT IS SUBJECT TO A UNITED STATES GOVERNMENT EMBARGO, OR THAT HAS BEEN DESIGNATED BY THE UNITED STATES GOVERNMENT AS A “TERRORIST SUPPORTING” COUNTRY; AND YOU ARE NOT LISTED ON ANY UNITED STATES GOVERNMENT LIST OF PROHIBITED OR RESTRICTED PARTIES.
            </p>
            <p>
            Some states or countries do not allow the exclusion of certain warranties or the limitations/exclusions of liability described above. So these limitations/exclusions may not apply to you if you reside in one of those states or countries.
            </p>
            <h2>Indemnity</h2>
            <p>
            You agree to defend, indemnify and hold harmless MAN OF EERIE LETTERS, any third-parties under agreement with MAN OF EERIE LETTERS, any parent, subsidiary or affiliate of MAN OF EERIE LETTERS, and any employee, agent, supplier, licensee, customer, distributor, shareholder, director or officer of any of the foregoing, as well as any person using the Services and any person or entity that becomes aware of your use of the Services at any time, with respect to any and all claims, liabilities, injuries, damages, losses or expenses (including but not limited to attorney’s fees and costs) that arise under, from or in any way, directly or indirectly, relate to:
            </p>
            <ol>
                <li>
                Your failure to comply with any provision of this agreement;
                </li>
                <li>
                Your use of the Services, including but not limited to economic, physical, emotional, psychological or privacy related considerations; and
                </li>
                <li>
                Your actions to knowingly affect the Services via any bloatware, malware, computer virus, worm, Trojan horse, spyware, adware, crimeware, scareware, rootkit or any other program installed in a way that executable code of any program is scheduled to utilize or utilizes processor cycles during periods of time when such program is not directly or indirectly being used.
                </li>
            </ol>
            <h2>Dispute Resolution</h2>
            <ol>
                <li>
                    <strong>Informal Resolution.</strong> With respect to the resolution of any controversy related to this agreement (hereinafter “Dispute”) you agree to try to resolve any Dispute informally for at least thirty (30) days before initiating any arbitration or other proceeding, including any legal proceeding in court or before an administrative agency.
                </li>
                <li>
                    <strong>Contact Information.</strong> MAN OF EERIE LETTERS can be reached at the following address: <strong>MAN OF EERIE LETTERS LLC</strong>, 165 E. 72ND ST., APT. 17A, NEW YORK, NY 10021
                </li>
                <li>
                    <strong>Mandatory Binding Arbitration.</strong> If you are not able to satisfactorily resolve a Dispute informally within a total of ninety (90) days or if MAN OF EERIE LETTERS, in its sole and absolute discretion, determines that it will not be possible to satisfactorily resolve that Dispute informally within a total of ninety (90) days, you agree that either you or MAN OF EERIE LETTERS may request resolution by final and fully binding arbitration conducted under the Commercial Arbitration Rules of the American Arbitration Association. 
                </li>
            </ol>
            <ul>
                <li>
                The American Arbitration Association (AAA) will run the arbitration between you and MAN OF EERIE LETTERS, and AAA's rules and procedures (including their Supplementary Procedures for Consumer-Related Disputes, if applicable) will be used. If something in these Terms is different than AAA's rules and procedures, then we will follow these Terms instead. You can look at AAA's rules and procedures on their website www.adr.org or you can call them at 1-800-778-7879.
                </li>
                <li>
                YOU UNDERSTAND, AND FURTHER AGREE, THAT YOU HAVE THE RIGHT TO CONSULT WITH INDEPENDENT LEGAL COUNSEL OF YOUR OWN CHOOSING REGARDING THIS AND ANY OTHER PROVISION IN THIS AGREEMENT AND THAT THIS BINDING ARBITRATION PROVISION WILL ELIMINATE YOUR LEGAL RIGHT TO SUE IN COURT AND/OR HAVE A JURY TRIAL WITH RESPECT TO ANY SUCH DISPUTE. Accordingly, you and MAN OF EERIE LETTERS agree that neither shall attempt to have any other Dispute related to any other party, including but not limited to any class action, joined to any arbitration in which you are involved. Thus, to the fullest extent permitted by law no arbitration proceeding shall be joined with any other or decided on a class-action basis.
                </li>
            </ul>
            <h2>11. General Provisions</h2>
            <ol>
                <li>
                It is your responsibility to read, understand and accept this agreement in connection with your use of the Services. You acknowledge that MAN OF EERIE LETTERS may make changes to the provisions of this agreement at any time and that Section headings in this agreement are for purposes of convenience only.
                </li>
                <li>
                    <strong>Force Majeure.</strong> Both you and MAN OF EERIE LETTERS shall be excused from any failure to perform any obligation under this agreement to the extent such failure is caused by war, terrorism, acts of public enemies, strikes or other labor disturbances, fires, floods, acts of god, or any causes of like or different kind beyond the control of you or MAN OF EERIE LETTERS, as applicable under the circumstances.
                </li>
                <li>
                    <strong>No Waiver.</strong> If we do not enforce a provision of these Terms, or our Privacy Policy, that does not waive our right to do so later. And, if We do expressly waive a provision of these Terms, or our Privacy Policy that does not mean it is waived for all time in the future. Any waiver must be in writing and signed by both you and us to be legally binding.
                </li>
                <li>
                    <strong>Waiver.</strong> Signed written consent from a legally authorized representative of MAN OF EERIE LETTERS is required to waive any provisions of this agreement. Any waiver of any provision of this agreement, intentional or otherwise, shall not be deemed a waiver of any other provisions of this agreement.
                </li>
                <li>
                    <strong>Injunctive Relief.</strong> You and MAN OF EERIE LETTERS acknowledge that breach of this agreement may result in irreparable harm and loss, and upon a breach of this agreement the non-breaching party (i.e. you and/or MAN OF EERIE LETTERS) shall be entitled to immediate injunctive relief from a court of competent jurisdiction, which is in addition to, not in lieu of remedies at law and/or any other remedies set forth in this agreement.
                </li>
                <li>
                    <strong>Governing Law and Venue.</strong> This agreement and any controversy related to this agreement shall be interpreted in accordance with and governed by the laws of the state of New York, or, as appropriate, by federal law as applied by a federal court sitting in New York, both without regard to New York choice of law rules.
                </li>
            </ol>
            <p>
            <Link 
                to='./privacy-policy' 
                className='other-legal-footer-link'>
                    Privacy Policy
            </Link>
            </p>
        </div>
    )
}

export default Terms