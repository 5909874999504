import React, { useEffect } from 'react';
import './Legal.css';
import { Link } from 'react-router-dom';

const Privacy = ({ toggleHeader }) => {
  useEffect(() => {
    toggleHeader(false);
  }, [toggleHeader]);
  return (
    <div>
      <Link to='./terms-of-service' className='other-legal-link'>
        Terms of Service
      </Link>
      <h1>Privacy Policy</h1>
      <p>
        Privacy is something we take very seriously here at MAN OF EERIE LETTERS
        LLC (“MAN OF EERIE LETTERS”), and it is our goal to be open and
        transparent with what information we collect, and how we use it. This
        website, products, mobile applications, console or PC applications, and
        any services (collectively “Services”) linked by this Privacy Policy
        (“Policy”) are controlled by MAN OF EERIE LETTERS and its affiliates
        (“we”, “our”, or “we”).
      </p>
      <p>
        This Policy will explain what information we collect, why we collect it,
        and how we use it. Additionally, this Policy explains the choices we
        offer, including how to access and update your information.
      </p>
      <p>
        If you have any questions about this Policy, please contact us at:{' '}
        <strong>reeseeifler@gmail.com</strong>.
      </p>
      <p>
        By using the Services, you are consenting to this Policy and the
        processing of your data, including your personal information, in the
        manner provided in this Policy. If you do not agree with these terms,
        please do not use the Services.
      </p>
      <h2>Information we collect</h2>
      <p>We collect information in the following way:</p>
      <h3>
        Information you give us or information provided through a social
        network.
      </h3>
      <p>
        We work with social networks including, but not limited to Facebook, X
        and LinkedIn. We have access to information you directly provide and
        information through those social networking services based on your
        privacy settings on those networks.
      </p>
      <h3>Third Party Operators and Services</h3>
      <p>
        Our Privacy Policy does not apply to services offered by other companies
        or individuals, including products or sites that may be displayed to you
        on the Services. We also do not control the privacy policies and your
        privacy settings on third-party sites, including social networks.
      </p>
      <p>
        For further information, please see the relevant privacy policies for
        each Third Party Operator:
      </p>
      <ul>
        <li>
          <strong>Apple:</strong>
          <a href='https://www.apple.com/legal/privacy' className='legal-link'>
            https://www.apple.com/legal/privacy
          </a>
        </li>
      </ul>
      <h3>Enforcement</h3>
      <p>
        We regularly review our compliance with our Privacy Policy. When we
        receive formal written complaints, we will contact the person who made
        the complaint to follow up.
      </p>
      <h3>Changes</h3>
      <p>
        Our Privacy Policy may change from time to time. We will post any Policy
        changes on this page and, if the changes are significant, we will
        provide a more prominent notice (including, for certain Services, email
        notification of privacy policy changes).
      </p>
      <p>This Privacy Policy was last updated on Jun 1, 2024.</p>
      <p className='other-legal-footer-link'>
        <Link to='./terms-of-service' className='black-link'>
          Terms of Service
        </Link>
      </p>
    </div>
  );
};

export default Privacy;
