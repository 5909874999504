import './Home.css'
import React from 'react'
import vvCover from '../assets/VoronoiVoyager.png'
import vvPromo from '../assets/VoronoiVoyagerPromo.mp4'
import moelVideo from '../assets/manOfEerieLettersLOGO.mp4'
import moelLogo from '../assets/MANOFEERIELETTERS.png'
// import Video from '../assets/manOfEerieLettersLOGO.mp4'
// import Poster from '../assets/MANOFEERIELETTERS.png'
// import { Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const Home = ({ toggleHeader }) => {
    return (
        <div>
            <video 
                className='ui fluid image'
                poster={vvCover} 
                controls 
                autoPlay={false} 
                muted={false} 
                src={vvPromo} type='video/mp4'
            />
            <br/>
            <div className='center'>
                <video 
                    className='ui large image'
                    poster={moelLogo} 
                    controls 
                    autoPlay={false} 
                    muted={false} 
                    src={moelVideo} type='video/mp4'
                />
            </div>
            <hr/>
            <div className='center'>
                <Link 
                    to='/privacy-policy'
                    className='legal-link'
                    onClick={() => toggleHeader(false)}
                >
                    Privacy Policy
                </Link>
                <Link 
                    to='/terms-of-service'
                    className='legal-link'
                    onClick={() => toggleHeader(false)}
                >
                    Terms of Service
                </Link>
            </div>
            <div className='center'>
                <h2>
                    Copyright 2022, MAN OF EERIE LETTERS LLC
                </h2>
            </div>
        </div>
    );
};

export default Home;